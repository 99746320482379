<template>
  <section class="user-list">
    
    <div class="page-header">
      <h3 class="page-title">Users List</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link :to="`/users/add-user`" class="btn btn btn-add btn-primary">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h4  class="card-title">CMS Users</h4>
          <div class="table-responsive mb-0 pb-3">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="info">
                <tr v-for="(item, index) in info.cms_users" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.first_name }}</td>
                  <td>{{ item.last_name }}</td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/users/edit-user/${item.id}`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="View"
                      :to="`/users/user-info/${item.id}`">
                      <i class="mdi mdi-eye "></i>
                    </router-link>
                    
                    <a v-on:click="deleteFile(item.id)" class="table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="info == ''">
            No data found!
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h4  class="card-title">Client Users</h4>
          <div class="table-responsive mb-0 pb-3">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody v-if="info">
                <tr v-for="(item, index) in info.client_users" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.first_name }}</td>
                  <td>{{ item.last_name }}</td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.email }}</td>
                  <!-- <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/users/edit-user/${item.id}`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="View"
                      :to="`/users/user-info/${item.id}`">
                      <i class="mdi mdi-eye "></i>
                    </router-link>
                    
                    <a v-on:click="deleteFile(item.id)" class="table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="info == ''">
            No data found!
          </div>
        </div>
      </div>
    </div>
 
   
  </section>
</template>

<script>
import { userApi } from "../../api";

export default {
  name: "users",
  components: {
    
  },
  data() {
    return {
      info: null,
      
    };
  },
  mounted() {
    this.userList();
  },
  methods: {
    async userList() {
      
      const { data } = await userApi.userList();
      this.info = data.data.data;
      
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          
          const response = userApi.deleteUser(id).then((response) => {
            this.userList();
            
            if (response.data.code == 200) {
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
